/* Brand Colors */
$brand-primary: #007ea7;
$brand-secondary: #3f916a;
$brand-tertiary: #550c18;
$brand-dark: #363635;
$brand-light: #CCDBDC;

/* Bootstrap Overrides */
$body-color-light: $brand-dark;
$body-color-dark: $brand-light;
$body-back-color-light: $brand-light;
$body-back-color-dark: $brand-dark;
$grid-columns: 24;

$body-panel-light: rgba(255, 255, 255, 0.7);
$body-panel-dark: rgba(0, 0, 0, 0.4);

$primary: $brand-primary;
$secondary: $brand-secondary;
$danger: #af001d;
$warning: #c79500;

$link-color: $brand-primary;

$site-width: 1170px;

$grid-breakpoints: (
    xs: 0,
    sm: 630px,
    md: 980px,
    lg: 1080px,
    xl: 1460px
);

$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: $site-width
);

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.2;
$border-radius: 5px;