@import 'a_settings/_global';

body {
    margin: 0;
    padding: 0;
}
#splash {
    position: fixed;
    z-index: 1000000;
    background-color: $body-back-color-light;
    width: 100%;
    height: 100%;
    opacity: 1;
    
}
@media(prefers-color-scheme: dark) {
    #splash {
        background-color: $body-back-color-dark;
    }
}
#splash picture {
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}